import React, { useEffect, useRef } from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'
import Confetti from 'react-confetti'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import Loading from '@/shared/Loading'
import EventsIndex from '@/pages/Events/Index'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/events', name: 'Events' }
  ]
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const buttons = []

  const titleInfo = {
    title: 'Events',
    buttons: buttons
  }

  return titleInfo
}

export default function EventsContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()
  const [, setToast] = useGlobalState('toast')
  const { getRequest } = useQuery()
  const scrollRef = useRef(null)

  useEffect(() => {
    scrollRef?.current?.scrollIntoView()
  }, [])

  return <>
    <div ref={scrollRef} />
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`${index}${crumb.name}`} to={crumb.to} className="text-xs sm:text-sm text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path={['/events']}>
        <EventsIndex />
      </Route>
    </Switch>
  </>
}
