import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Card from '@/shared/Card'
import { TippyTooltip } from '@/shared/ToolTip'
import useQuery from '@/hooks/useQuery'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useGlobalState } from '@/state'
import { SVGIcon } from '@/components/icon'
import { ClockIcon, SpeakerphoneIcon, MicrophoneIcon, StatusOnlineIcon, StatusOfflineIcon, UserGroupIcon } from '@heroicons/react/solid'
import PublicActivity from '@/pages/Users/PublicProfile/PublicActivity'
import PublicUserRow from '@/pages/Users/PublicProfile/PublicUserRow'

const userMetaList = {
  'voiceActor': { image: 'voiceactor', text: 'Voice Actor', to_db: 'voice_actor' },
  'liveActor': { image: 'actor', text: 'Actor', to_db: 'live_actor' },
  'singer': { image: 'singer', text: 'Singer', to_db: 'singer' },
  'artist': { image: 'artist', text: 'Artist', to_db: 'artist' },
  'animator': { image: 'animator', text: 'Animator', to_db: 'animator' },
  'videoEditor': { image: 'video-editor', text: 'Video Editor', to_db: 'video_editor' },
  'musicComposer': { image: 'musiccomposer', text: 'Music Composer', to_db: 'music_composer' },
  'audioEngineer': { image: 'audio-engineer', text: 'Audio Engineer', to_db: 'audio_engineer' },
  'writer': { image: 'writer', text: 'Writer', to_db: 'writer' },
  'director': { image: 'director', text: 'Director', to_db: 'director' },
  'agent': { image: 'agent', text: 'Agent', to_db: 'agent' },
  'producer': { image: 'producer', text: 'Producer', to_db: 'producer' }
}

const FeaturedUsers = ({ profileUser }) => {
  const [featuredUsers, setFeaturedUsers] = useState([])
  const { getRequest } = useQuery()

  useEffect(() => {
    if (!profileUser.showFeaturedMembers) { return }

    getRequest('/api/v3/users/featured', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setFeaturedUsers(jsonData.featuredUsers)
    })
  }, [])

  if (featuredUsers.length === 0) { return null }

  return (
    <div className='mt-3'>
      <Card light title='Featured Members'>
        <ul className="space-y-3">
          {featuredUsers.map((user, idx) => (
            <PublicUserRow key={`fuser${user.id}`} user={user} followingUserIds={[]} />
          ))}
        </ul>
      </Card>
    </div>
  )
}

const SocialLink = ({ url, icon, text }) => {
  if (!url) { return null }
  const [, setToast] = useGlobalState('toast')

  const showCopiedNotification = () => {
    setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium dark:text-green-300 text-green-600">Copied</p>
      <p className="mt-1 text-sm dark:text-green-300 text-green-400">Discord ID is copied</p>
    </div>)
  }

  let href = url
  if (icon === 'twitter') {
    href = `https://www.twitter.com/@${url}`
  }

  if (icon === 'discord') {
    return <CopyToClipboard text={url} onCopy={showCopiedNotification} >
      <button className="text-gray-500 dark:text-gray-200 hover:text-cccblue-alt dark:hover:text-cccblue-alt">
        <span className="sr-only" hidden>{text}</span>
        <SVGIcon name={icon} width='40' height='40' autoDark/>
      </button>
    </CopyToClipboard>
  }

  return <a target="_blank" href={href} rel='noreferrer' className="text-gray-500 dark:text-gray-200 hover:text-cccblue-alt dark:hover:text-cccblue-alt">
    <span className="sr-only" hidden>{text}</span>
    <SVGIcon name={icon} width='40' height='40' autoDark/>
  </a>
}

const AchievementRow = ({ achievement }) => {
  return <span className="flex mr-2">
    <TippyTooltip content={
      <div className='flex flex-col items-center'>

        <img src={achievement.imageUrl} className='h-12 w-12' />
        <div className='font-medium leading-6 bg-white dark:bg-gray-800 text-cccorange dark:text-cccpurple'> {achievement.text} </div>
        <p>{achievement.description}</p>
      </div>
      }>
      <span className={'dark:text-gray-200 dark:bg-gray-700 bg-gray-100 inline-flex items-center px-2 py-1 text-sm rounded-md'}>
        <img src={achievement.imageUrl} className='h-6 w-6' />
      </span>
    </TippyTooltip>
  </span>
}

const Achievements = () => {
  const [profileContainer] = useGlobalState('profileContainer')
  const { getRequest } = useQuery()
  const [achievements, setAchievements] = useState([])
  const { user } = profileContainer

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    getRequest(`/api/v3/users/${user.username}/achievements`, {}, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setAchievements(jsonData.achievements)
    })
  }

  if (achievements.length === 0) { return null }

  return (
    <div className='mt-3'>
      <Card light title='Achievements'>
        <div className="flex flex-wrap">
          {achievements.map((act, idx) => (
            <AchievementRow key={`ach${act.id}`} achievement={act} />
          ))}
        </div>
      </Card>
    </div>
  )
}

const Sidebar = () => {
  const [profileContainer] = useGlobalState('profileContainer')
  const { user, genderAges, accents, languages, skills, interests, hardwares, softwares, userMeta } = profileContainer
  return <>
    <aside className="col-span-3 sm:col-span-1">
      <Card light title='Details'>
        <div className='flex space-2 flex-wrap'>
          { userMeta.map((key, idx) => (
            <span key={`usermeta${key}`} className="ml-1">
              <TippyTooltip content={<>{userMetaList[key].text}</>}>
                <span className={'dark:text-gray-200 dark:bg-gray-700 bg-gray-100 inline-flex items-center p-1 text-sm rounded-md'}>
                  <SVGIcon name={userMetaList[key].image} width='40' height='40' autoDark/>
                </span>
              </TippyTooltip>
            </span>
          ))}
        </div>
        <div className="space-y-5">
          { user.canShowOnline && <div className="flex items-center space-x-2">
            { user.isOnline && <>
              <StatusOnlineIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
              <span className="text-green-700 dark:text-green-400 text-sm font-medium">Online</span>

              </> }
            { !user.isOnline && <>
              <StatusOfflineIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              <span className="text-sm font-medium dark:text-gray-400">Offline</span>
              </> }
          </div> }

          { user.auditionsCount > 0 && <div className="flex items-center space-x-2">
            <MicrophoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span className="text-gray-900 dark:text-gray-100 text-sm font-medium">{user.auditionsCount}<span className='text-gray-500'> submissions</span></span>
          </div> }
          { user.projectsCount > 0 && <div className="flex items-center space-x-2">
            <SpeakerphoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span className="text-gray-900 dark:text-gray-100 text-sm font-medium">{user.projectsCount}<span className='text-gray-500'> projects created</span></span>
          </div> }
          <div className="flex items-center space-x-2">
            <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span className="text-gray-900 dark:text-gray-100 text-sm font-medium"><span className='text-gray-500'>Last seen</span> {user.lastSeenAt}<span className='text-gray-500'> ago</span></span>
          </div>
          { user.affiliatesCount > 0 && <div className="flex items-center space-x-2">
            <UserGroupIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span className="text-gray-900 dark:text-gray-100 text-sm font-medium">{user.affiliatesCount}<span className='text-gray-500'> members invited</span></span>
          </div> }
        </div>
        <div className='flex space-2 flex-wrap mt-2'>
          <SocialLink url={user.twitter} icon='twitter' text='Twitter' />
          <SocialLink url={user.website} icon='linktree' text='Website' />
          <SocialLink url={user.facebook} icon='facebook' text='Facebook' />
          <SocialLink url={user.youtube} icon='youtube' text='YouTube' />
          <SocialLink url={user.soundcloud} icon='soundcloud' text='Soundcloud' />
          <SocialLink url={user.patreon} icon='patreon' text='Donation Link' />
          <SocialLink url={user.linkedin} icon='linkedin' text='LinkedIn' />
          <SocialLink url={user.tumblr} icon='tumblr' text='Tumblr' />
          <SocialLink url={user.discord} icon='discord' text='Discord' />
          <SocialLink url={user.skype} icon='skype' text='Skype' />
          <SocialLink url={user.imdb} icon='imdb' text='IMDB' />
          <SocialLink url={user.bsky} icon='bsky' text='Blue Sky' />
        </div>

        { (genderAges.length > 0 || languages.length > 0 || accents.length > 0) && <>
          <h3 className="text-lg mt-1 font-medium leading-6 text-gray-900 dark:text-gray-100">Voice Description</h3>
          <div className="mt-3 flex sm:items-center flex-wrap">
            {genderAges.map((labeling, idx) => (
              <span key={`lang${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
            ))}
            {languages.map((labeling, idx) => (
              <span key={`lang${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
            ))}
            {accents.map((labeling, idx) => (
              <span key={`lang${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
            ))}
          </div>
        </> }
        { (interests.length > 0 || skills.length > 0) && <>
          <h3 className="text-lg mt-1 font-medium leading-6 text-gray-900 dark:text-gray-100">Skills & Interests</h3>
          <div className="mt-3 flex sm:items-center flex-wrap">
            {interests.map((labeling, idx) => (
              <span key={`lang${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
            ))}
            {skills.map((labeling, idx) => (
              <span key={`lang${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
            ))}
          </div>
        </> }
        { (hardwares.length > 0 || softwares.length > 0) && <>
          <h3 className="text-lg mt-1 font-medium leading-6 text-gray-900 dark:text-gray-100">Equipment</h3>
          <div className="mt-3 flex sm:items-center flex-wrap">
            {hardwares.map((labeling, idx) => (
              <span key={`lang${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
            ))}
            {softwares.map((labeling, idx) => (
              <span key={`lang${labeling.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling.name}</span>
            ))}
          </div>
        </> }
      </Card >
      <div className='mb-4' />
      { user.canShowActivity && <PublicActivity /> }
      <div className='mt-4' />
      { user.canShowAchievements && <Achievements /> }
      <div className='mt-4' />
      { <FeaturedUsers profileUser={user} /> }
    </aside>
  </>
}

export default Sidebar

AchievementRow.propTypes = {
  achievement: PropTypes.object
}

FeaturedUsers.propTypes = {
  profileUser: PropTypes.object
}

SocialLink.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string
}
