import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useGlobalState } from '@/state'
import Card from '@/shared/Card'

export const DivOrigami = () => {
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  if (!currentUser) return null

  if (currentUser?.auditionStreak === 0) {
    return (
      <LogoRolodex
        items={[
          <LogoItem key={1} className="bg-cccblue text-neutral-900">
            {currentUser?.auditionStreak}
          </LogoItem>,
        ]}
      />
    );
  }
  return (
    <LogoRolodex
      items={[
        <LogoItem key={1} className="bg-cccblue text-neutral-900">
          {currentUser?.auditionStreak - 1}
        </LogoItem>,
        <LogoItem key={2} className="bg-cccorange text-neutral-900">
          {currentUser?.auditionStreak}
        </LogoItem>,
      ]}
    />
  );
};

const DELAY_IN_MS = 2500;
const TRANSITION_DURATION_IN_SECS = 1.5;

const LogoRolodex = ({ items }) => {
  const intervalRef = useRef(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setIndex((pv) => pv + 1);
    }, DELAY_IN_MS);

    return () => {
      clearInterval(intervalRef.current || undefined);
    };
  }, []);

  if (index >= items.length - 1) {
    clearInterval(intervalRef.current || undefined);
  }

  return (
    <Card title='Submission Streak'>
      <p className="text-center max-w-lg mx-auto text-slate-800 dark:text-white">
        A good habit to keep up! Practice makes perfect.
      </p>
      <div className='flex justify-center items-center gap-x-4'>
        <p className="text-center text-3xl font-black text-slate-800 dark:text-white">
          Submission Streak :
        </p>
        <div
          style={{
            transform: "rotateY(-20deg)",
            transformStyle: "preserve-3d",
          }}
          className="relative z-0 h-24 w-40 shrink-0 rounded-xl border border-neutral-700"
        >
          <AnimatePresence mode="sync">
            <motion.div
              style={{
                y: "-50%",
                x: "-50%",
                clipPath: "polygon(0 0, 100% 0, 100% 50%, 0 50%)",
                zIndex: -index,
                backfaceVisibility: "hidden",
              }}
              key={index}
              transition={{
                duration: TRANSITION_DURATION_IN_SECS,
                ease: "easeInOut",
              }}
              initial={{ rotateX: "0deg" }}
              animate={{ rotateX: "0deg" }}
              exit={{ rotateX: "-180deg" }}
              className="absolute left-1/2 top-1/2"
            >
              {items[index % items.length]}
            </motion.div>
            <motion.div
              style={{
                y: "-50%",
                x: "-50%",
                clipPath: "polygon(0 50%, 100% 50%, 100% 100%, 0 100%)",
                zIndex: index,
                backfaceVisibility: "hidden",
              }}
              key={(index + 1) * 2}
              initial={{ rotateX: "180deg" }}
              animate={{ rotateX: "0deg" }}
              exit={{ rotateX: "0deg" }}
              transition={{
                duration: TRANSITION_DURATION_IN_SECS,
                ease: "easeInOut",
              }}
              className="absolute left-1/2 top-1/2"
            >
              {items[index % items.length]}
            </motion.div>
          </AnimatePresence>

          <hr
            style={{
              transform: "translateZ(1px)",
            }}
            className="absolute left-0 right-0 top-1/2 z-[999999999] -translate-y-1/2 border-t-2 border-neutral-800"
          />
        </div>
        <p className="text-center text-3xl font-black text-slate-800 dark:text-white">
          Days
        </p>
      </div>
      <p className="text-center max-w-lg text-xs mx-auto text-slate-800 dark:text-gray-400">
        Streaks are a measure of how many days in a row you have created a submission.
        Streaks are updated daily at 10:00 AM UTC
      </p>
    </Card>
  );
};

const LogoItem = ({ children, className }) => {
  return (
    <div className={`grid h-16 w-32 place-content-center rounded-lg text-6xl text-white ${className}`} >
      {children}
    </div>
  );
};
export default DivOrigami;
