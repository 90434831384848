import React, { Fragment, useState, useRef } from 'react'
import { Transition, Menu } from '@headlessui/react'
import { useGlobalState } from '@/state'
import PropTypes from 'prop-types'

export default function Sale (props) {
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')

  if (currentUser?.gold) return null

  return (
    <div>
      <div className="relative z-1 flex-shrink-0 flex bg-cccpurple">
        <div className="flex-1 items-center flex justify-between text-white dark:bg-cccpurple py-1 px-4">
          <p class="text-xs sm:text-base">Holiday Sale! Business Yearly Plan. Save $264.</p>

            <div class="flex gap-2">
              <a href='/subscriptions' type="button" class="bg-white dark:text-cccblue py-2.5 px-5 rounded text-xs sm:text-base hover:bg-gray-100">
                Ooooo. I'll take a look.
              </a>
            </div>
        </div>
      </div>
    </div>
  )
}

Sale.propTypes = { }
