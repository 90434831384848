import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Confetti from 'react-confetti'
import SVGIcon from '@/components/icon'
import Tabs from '@/shared/Tabs'
import Loading from '@/shared/Loading'
import { SidebarAd, MobileAd, SidebarEducationAd, MobileEducationAd, MobileVideomancyAd, SidebarVideomancyAd } from '@/Layout/Ads'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import UserHoverCard from '@/shared/UserHoverCard'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <SVGIcon name='icon-messages' className='mx-auto h-7 w-7 sm:h-12 sm:w-12' />
        <span className="mt-2 block font-medium text-gray-900 dark:text-gray-300"> No notifications.</span>
      </div>
    </span>
  )
}
const Congratulations = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <SVGIcon name='icon-messages' className='mx-auto h-7 w-7 sm:h-12 sm:w-12' />
        <span className="mt-2 block font-medium text-gray-900 dark:text-gray-300"> You read everything! Time for a nap?</span>
      </div>
    </span>
  )
}

const AlertsIndex = () => {
  const { getRequest, putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const [loading, setLoading] = useState(true)
  const [readcount, setReadcount] = useState(0)
  const [clickedMarkAllAsRead, setClickedMarkAllAsRead] = useState(false)
  const [status, setStatus] = useState('unread')
  const [alerts, setAlerts] = useState([])
  const statuses = ['unread', 'read']

  const changeTab = (tab) => { setStatus(tab) }
  const tablist = [
    { name: 'Unread', count: null, changeValue: 'unread' },
    { name: 'Archive', count: null, changeValue: 'read' }
  ]

  const fetchData = () => {
    setLoading(true)
    const data = { status: status }
    getRequest('/api/v3/alerts', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setAlerts(jsonData.alerts)
    })
  }

  const updateReadcount = () => { setReadcount(readcount + 1) }

  const markAllRead = () => {
    setAlerts([])
    setClickedMarkAllAsRead(true)
    putpostRequest(`/api/v3/alerts/mark_all_as_read`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hooks */ }
    })
  }

  useEffect(() => { fetchData() }, [status])

  return <>
    { status === 'unread' && readcount === alerts.length && readcount > 0 && <Confetti width={window.innerWidth} gravity={0.05} colors={['#f7784e', '#00b0e7', '#6452a2']} numberOfPieces={300} recycle={false} height={window.innerHeight} /> }
    { clickedMarkAllAsRead && <Confetti width={window.innerWidth} gravity={0.05} colors={['#f7784e', '#00b0e7', '#6452a2']} numberOfPieces={300} recycle={false} height={window.innerHeight} /> }
    <div className='flex flex-col gap-y-1'>
      <MobileVideomancyAd />
      <MobileEducationAd />
      <MobileAd adID={109} />
    </div>
    <div className='lg:block lg:flex lg:gap-x-2'>
      <div id='notifications' className='max-w-6xl w-full'>
        <Card title={'Notifications'} header={
          <span className='text-cccorange cursor-pointer bg-white px-2 py-1 rounded-md' onClick={markAllRead}>Mark all as Read</span>
        }>
          <>
            { loading && <Loading /> }
            <Tabs statuses={statuses} tablist={tablist} currentTab={status} changeTab={changeTab} />
            <div className="flex flex-col gap-y-1">
              {alerts.map((alert) => <AlertRow key={`alert${alert.id}`} alert={alert} status={status} updateReadcount={updateReadcount} />)}
            </div>
            {alerts.length === 0 && !clickedMarkAllAsRead && <NoResults />}
            { status === 'unread' && readcount === alerts.length && readcount > 0 && <Congratulations /> }
            { clickedMarkAllAsRead && <Congratulations /> }
          </>
        </Card>
      </div>
      <div className='flex flex-col gap-y-1'>
        <SidebarVideomancyAd />
        <SidebarEducationAd />
        <SidebarAd adID={108} />
      </div>
    </div>
  </>
}

const AlertRow = (props) => {
  const { alert, status, updateReadcount } = props
  const [read, setRead] = useState(alert.read)
  const { putpostRequest } = useQuery()

  const markRead = () => {
    setRead(true)
    updateReadcount()
    putpostRequest(`/api/v3/alerts/${alert.id}/mark_as_read`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hooks */ }
    })
  }
  if (status === 'unread' && read) { return null }

  return <div className={`${read ? 'bg-white dark:bg-gray-700' : 'bg-cccblue bg-opacity-10'} rounded-lg border border-gray-200 dark:border-gray-700 p-1`}>
    <div className='flex flex-col sm:flex-row justify-between'>
      { alert.data.type === 'new_audition_on_your_project' && <NewAuditionOnYourProject alert={alert} /> }
      { alert.data.type === 'new_recommendation' && <NewRecommendation alert={alert} /> }
      { alert.data.type === 'new_coins_gift' && <NewCoinsGift alert={alert} /> }
      { alert.data.type === 'new_coins' && <NewCoins alert={alert} /> }
      { alert.data.type === 'new_follower_project' && <NewFollowerProject alert={alert} /> }
      { alert.data.type === 'following_user_assigned_to_role' && <FollowingUserAssignedToRole alert={alert} /> }
      { alert.data.type === 'removed_from_role' && <RemovedFromRole alert={alert} /> }
      { alert.data.type === 'assigned_to_role' && <AssignedToRole alert={alert} /> }
      { alert.data.type === 'new_audition_comment' && <NewAuditionComment alert={alert} /> }
      { alert.data.type === 'new_project_comment' && <NewProjectComment alert={alert} /> }
      { alert.data.type === 'new_follower' && <NewFollower alert={alert} /> }
      { alert.data.type === 'new_project_follower' && <NewProjectFollower alert={alert} /> }
      { alert.data.type === 'new_mention' && <NewMention alert={alert} /> }
      { alert.data.type === 'new_upvoted_audition' && <NewUpvotedAudition alert={alert} /> }
      { alert.data.type === 'successful_audition_upload' && <SuccessfulAuditionUpload alert={alert} /> }
      { alert.data.type === 'upload_failed' && <UploadFailed alert={alert} /> }
      { alert.data.type === 'new_user_credit' && <NewUserCredit alert={alert} /> }
      <div className="mt-0.5 text-sm text-gray-800 dark:text-gray-200 flex sm:flex-col gap-2 justify-between">
        <span className='flex justify-end'>{alert.timeAgo}</span>
        { !read && <span className='text-cccorange cursor-pointer bg-white px-2 py-1 rounded-md' onClick={markRead}>Mark as Read</span> }
      </div>
    </div>
  </div>
}

const NewUserCredit = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col sm:flex-row gap-x-1'>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">You were credited for <b>{alert.data.role_name}</b> in <b>{alert.data.credit_name}</b>! Nice!</p>
      </div>
      <a href={`/credits`} className="font-medium text-cccblue cursor-pointer truncate">
        Approve or Deny It
      </a>
    </div>
  </div>
}

const UploadFailed = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col sm:flex-row gap-x-1'>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">Your <b>{alert.data.kind}</b> upload failed. {JSON.stringify(alert.data.errors)}</p>
      </div>
      <span className="text-xs text-gray-800 dark:text-gray-200"> Please try again.</span>
    </div>
  </div>
}

const SuccessfulAuditionUpload = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col sm:flex-row gap-x-1'>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">Your upload for <b>{alert.data.role_name}</b> was successfully submitted. The project owner will review it.</p>
      </div>
      <a href={`/submissions/${alert.data.audition_id}`} className="font-medium text-cccblue cursor-pointer truncate">
        View Submission
      </a>
      <span className="text-xs text-gray-800 dark:text-gray-200"> You received this notification because you enabled it in your Settings </span>
    </div>
  </div>
}

const NewUpvotedAudition = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col sm:flex-row gap-x-1'>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">Your submission for role <b>{alert.data.role_name}</b> now has <b>{alert.data.total_upvotes}</b> upvotes!</p>
      </div>
      <a href={`/submissions/${alert.data.audition_id}`} className="font-medium text-cccblue cursor-pointer truncate">
        View Submission
      </a>
    </div>
  </div>
}

const NewMention = (props) => {
  const { alert } = props
  let url
  if (alert.data.object_type === 'Audition') { url = `/submissions/${alert.data.object_id}` }
  if (alert.data.object_type === 'Project') { url = `/projects/${alert.data.object_id}` }
  if (alert.data.object_type === 'Comment' && alert.data.commentable_type === 'Project') { url = `/projects/${alert.data.object_id}` }
  if (alert.data.object_type === 'Comment' && alert.data.commentable_type === 'Audition') { url = `/submissions/${alert.data.object_id}` }
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col'>
        <div className='flex gap-x-1'>
          <span className='text-gray-800 dark:text-gray-200'>You were mentioned in a comment.</span>
        </div>
        { url && <a href={url} className="font-medium text-cccblue cursor-pointer truncate"> View comment </a> }
      </div>
    </div>
  </div>
}

const NewProjectFollower = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col sm:flex-row gap-x-1'>
        <UserHoverCard username={alert.data.follower_username} displayName={alert.data.follower_display_name} />
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">started following your project {alert.data.project_name}. </p>
      </div>
    </div>
  </div>
}

const NewFollower = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col sm:flex-row gap-x-1'>
        <UserHoverCard username={alert.data.follower_username} displayName={alert.data.follower_display_name} />
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">started following you. Maybe you should follow them back! </p>
      </div>
      <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">See all your followers on your public profile.</p>
    </div>
  </div>
}

const NewProjectComment = (props) => {
  const { alert } = props
  const url = `/projects/${alert.data.project_id}`
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col'>
        <div className='flex gap-x-1'>
          <span className='text-gray-800 dark:text-gray-200'><b>{alert.data.comment_user_display_name}</b> commented on your project.</span>
        </div>
        <a href={url} className="font-medium text-cccblue cursor-pointer truncate"> {alert.data.project_name} </a>
      </div>
    </div>
  </div>
}

const NewAuditionComment = (props) => {
  const { alert } = props
  const url = `/submissions/${alert.data.audition_id}`
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col'>
        <div className='flex gap-x-1'>
          <span className='text-gray-800 dark:text-gray-200'><b>{alert.data.comment_user_display_name}</b> commented on your submission.</span>
        </div>
        <a href={url} className="font-medium text-cccblue cursor-pointer truncate"> {alert.data.role_name} </a>
      </div>
    </div>
  </div>
}

const AssignedToRole = (props) => {
  const { alert } = props
  const url = `/projects/${alert.data.project_id}`
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="relative m-1">
      <img className="h-8 w-8 sm:h-16 sm:w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={alert.data.image_url} alt="" />
    </div>
    <div className="min-w-0 flex-1">
      <div className='flex flex-col'>
        <div className='flex gap-x-1'>
          <span className='text-gray-800 dark:text-gray-200'>You were assigned to role: <b>{alert.data.name}</b></span>
        </div>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">Congratulations! The project creator will be in touch with you shortly with some instructions on what to do next.</p>
        <a href={url} className="font-medium text-cccblue cursor-pointer truncate"> {alert.data.project_name} </a>
      </div>
    </div>
  </div>
}

const RemovedFromRole = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col'>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">You were removed from role <b>{alert.data.name} people</b> </p>
      </div>
    </div>
  </div>
}

const FollowingUserAssignedToRole = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col'>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200"><b>{alert.data.count} people</b> were all assigned to a role today: </p>
        <i className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">{alert.data.ten_display_names.join(', ')}</i>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">Be sure to congratulate them!</p>
        <span className="text-xs text-gray-800 dark:text-gray-200"> You received this notification because you follow these people </span>
      </div>
    </div>
  </div>
}

const NewFollowerProject = (props) => {
  const { alert } = props
  const url = `/projects/${alert.data.project_id}`
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="relative m-1">
      <img className="h-8 w-8 sm:h-16 sm:w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={alert.data.image_url} alt="" />
    </div>
    <div className="min-w-0 flex-1">
      <div className='flex flex-col'>
        <div className='flex gap-x-1'>
          <span className='text-gray-800 dark:text-gray-200'>{alert.data.sender_username}</span>
        </div>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">{alert.data.message}</p>
        <a href={url} className="font-medium text-cccblue cursor-pointer truncate"> {alert.data.project_name} </a>
        <span className="text-xs text-gray-800 dark:text-gray-200"> You received this notification because you follow this person </span>
      </div>
    </div>
  </div>
}

const NewCoins = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="min-w-0 flex-1">
      <div className='flex flex-col gap-y-2'>
        <div className="text-sm text-gray-800 dark:text-gray-200">You just got <b>{alert.data.amount}</b> cccCoins</div>
        <div className="text-sm text-gray-800 dark:text-gray-200">{alert.data.reason}</div>
      </div>
      <a href='/coins' className="font-medium text-cccblue cursor-pointer truncate">
        See your cccCoins
      </a>
    </div>
  </div>
}

const NewCoinsGift = (props) => {
  const { alert } = props
  let url
  if (alert.data.giftable_type === 'Audition') { url = `/submissions/${alert.data.giftable_id}` }
  if (alert.data.giftable_type === 'Project') { url = `/projects/${alert.data.giftable_id}` }
  if (alert.data.giftable_type === 'Comment') { url = null }
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="relative m-1">
      <img className="h-8 w-8 sm:h-16 sm:w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={alert.data.image_url} alt="" />
    </div>
    <div className="min-w-0 flex-1">
      <div className='flex flex-col'>
        <div className='flex gap-x-1'>
          <span className='text-gray-800 dark:text-gray-200'>{alert.data.sender_username}</span>
          <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">has awarded you <b>{alert.data.gift_name}</b></p>
        </div>
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">{alert.data.message}</p>
        { url && <a href={url} className="font-medium text-cccblue cursor-pointer truncate"> View Award </a> }
        <i className="text-xs text-gray-800 dark:text-gray-200"> That's so nice of them </i>
      </div>
    </div>
  </div>
}

const NewRecommendation = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="relative m-1">
      <img className="h-8 w-8 sm:h-16 sm:w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={alert.data.image_url} alt="" />
    </div>
    <div className="min-w-0 flex-1">
      <div className='flex flex-col sm:flex-row gap-x-1'>
        <UserHoverCard username={alert.data.giver_username} displayName={alert.data.giver_displayname} />
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">gave you a recommendation </p>
      </div>
      <a href='/recommendations' className="font-medium text-cccblue cursor-pointer truncate">
        Approve or Deny It
      </a>
    </div>
  </div>
}

const NewAuditionOnYourProject = (props) => {
  const { alert } = props
  return <div className="relative flex items-start space-x-3 overflow-hidden">
    <div className="relative m-1">
      <img className="h-8 w-8 sm:h-16 sm:w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccorange" src={alert.data.project_image_url} alt="" />
    </div>
    <div className="min-w-0 flex-1">
      <div className='flex flex-col sm:flex-row gap-x-1'>
        <UserHoverCard username={alert.data.audition_username} displayName={alert.data.audition_display_name} />
        <p className="mt-0.5 text-sm text-gray-800 dark:text-gray-200">entered a submission to your project </p>
      </div>
      <a href={`/submissions/${alert.data.audition_id}`} className="font-medium text-cccblue cursor-pointer truncate">
        {alert.data.project_name}
      </a>
    </div>
  </div>
}

AlertRow.propTypes = {
  alert: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  updateReadcount: PropTypes.func.isRequired
}
NewAuditionOnYourProject.propTypes = { alert: PropTypes.object.isRequired }
NewRecommendation.propTypes = { alert: PropTypes.object.isRequired }
NewCoinsGift.propTypes = { alert: PropTypes.object.isRequired }

export default AlertsIndex
