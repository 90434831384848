import React, { useState } from 'react'

import useModal from '@/hooks/useModalV2'
import Loading from '@/shared/Loading'
import Modal from '@/shared/Modal'
import ImageUploadFormR2 from '@/shared/ImageUploadFormR2'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

const ProfileGeneralAvatar = () => {
  const [user, setUser] = useGlobalState('userContainerUser')
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [url, setUrl] = useState(user.largeImageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v3/users/${user.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.url)
      setUser({ ...user, publicImageUrl: jsonData.url })
      setCurrentUser({ ...currentUser, avatarUrl: jsonData.url })
    })
  }

  return (
    <>
      <div className="mt-1 flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-sm relative">
        { !uploaded && <span>
            <img className='h-20 w-20 rounded-sm' src={url} onClick={openModal} alt={user.displayName} />
          </span>
          }
        { uploaded && <div className='relative h-20 w-20 rounded-sm flex items-center justify-center dark:bg-gray-700'>
          <img className='h-20 w-20 rounded-sm' src={url} onClick={openModal} alt={user.displayName} />
          <div className='absolute h-20 w-20 top-8'>
            <Loading noMessage noLoadingMessage />
          </div>
        </div> }
      </div>
      <Modal title="Edit Image" full isOpen={isOpen} closeModal={closeModal} >
        <div className='flex justify-center'>
          <div className="mt-2">
            <ImageUploadFormR2
              kind='User'
              url={url}
              setUrl={setUrl}
              success={reloadPublicImageURL}
              didUpload={setUploaded}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ProfileGeneralAvatar
