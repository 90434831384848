import React, { useState, useEffect, useRef } from 'react'
import PrimaryButton from '@/shared/Buttons/Primary'
import CommentForm from '@/shared/CommentForm'
import CommentRow from '@/shared/CommentRow'
import { ChatAltIcon, CalendarIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'
import { AnimatedModal, useModal } from '@/shared/AnimatedModal'
import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import EventRow from '@/pages/Events/Row'

const EventsIndex = (props) => {
  const { projectId } = props
  const { modalOpen, setModalOpen } = useModal()
  const [ attendingEventIds, setAttendingEventIds ] = useState([])
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')

  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(false)
  const scrollRef = useRef(null)
  const [events, setEvents] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setModalOpen(false)
    setLoading(true)
    getRequest(`/api/v3/events`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setLoading(false)
      setEvents(jsonData.events)
      setAttendingEventIds(jsonData.attendingEventIds)
    })
  }

  return <div className='mt-3'>
    <Card title={
      <div className='flex justify-between items-center'>
        <div className='font-medium leading-6 text-cccorange dark:text-cccpurple'>Events</div>
      </div>
      } header={
    <div className='flex flex-rows justify-end items-end'>
      <button onClick={() => setModalOpen(true)} className="ml-3 inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white dark:text-gray-200 dark:hover:bg-cccblue-alt bg-cccblue hover:bg-cccblue-alt hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 mb-2">
      <CalendarIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      New Event
    </button>
    </div>
    } >
      <div ref={scrollRef}>
        { loading && <Loading /> }
        { events.length === 0 && !loading && <NoResults /> }
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {events.map((event) => (
            <EventRow key={`event${event.id}`} event={event} user={currentUser} attendingEventIds={attendingEventIds} />
          ))}
        </div>
      </div>
    </Card>
    { modalOpen && <AnimatedModal maxWidth='max-w-xl' modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="relative p-8">
        <div className="sm:text-center">
          <h2 className="text-3xl font-extrabold text-slate-900 tracking-tight sm:text-4xl">New Event</h2>
        </div>
        <div className='w-70 sm:w-96 mx-auto mt-20'>
          Do you want to host your own event?
          <br />
          <br />
          CCC is currently considering if this is a good idea for the community to be able to host their own events. Share your feedback on the Canny board here:
          <br />
          <br />
          <a target="_blank" href='https://casting-call-club.canny.io/feature-requests-and-bug-reports/p/host-events-on-casting-call-club'>https://casting-call-club.canny.io/feature-requests-and-bug-reports/p/host-events-on-casting-call-club</a>
        </div>
      </div>
    </AnimatedModal> }
  </div>
}

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <ChatAltIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> There are no upcoming events.</span>
      </div>
    </span>
  )
}

export default EventsIndex
