// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from '@/App'
import Header from '@/Layout/Header'
import LoggedOutDemosIndex from '@/pages/Demos/LoggedOutDemosIndex'
import LoggedOutSubmissionShow from '@/pages/Submissions/LoggedOutSubmissionShow'
import LoggedOutTalentSearchIndex from '@/pages/TalentSearch/LoggedOutTalentSearchIndex'
import LoggedOutOpenProjectsSearchIndex from '@/pages/ProjectSearch/LoggedOutOpenProjectsSearchIndex'
import OnboardingUserMeta from '@/pages/OnboardingUserMeta'

import SaveForLaterButton from '@/shared/Buttons/SaveForLaterButton'
import ProjectFollowButton from '@/shared/Buttons/ProjectFollowButton'
import ReactionButton from '@/shared/Buttons/ReactionButton'
import PublicProjectSubmissionsIndex from '@/pages/PublicProjectSubmissionsIndex'
import PublicProjectCommentsIndex from '@/pages/PublicProjectCommentsIndex'
import SubmissionUploadEdit from '@/pages/SubmissionUpload/Edit'
import FlagProjectModal from '@/shared/FlagProjectModal'
import NewFeatureAlert from '@/shared/NewFeatureAlert'
import { ProjectAds } from '@/Layout/Ads'

const attachReact = (el, cb) => {
  const node = document.getElementById(el)
  if (node) { cb(node) }
}

document.addEventListener('DOMContentLoaded', () => {
  attachReact('react-spa-root', function (node) {
    const root = createRoot(node)
    root.render(
      <BrowserRouter>
        <App {...JSON.parse(node.getAttribute('data'))} />
      </BrowserRouter>
    )
  })
  attachReact('react-header', function (node) {
    const root = createRoot(node)
    root.render(
      <BrowserRouter>
        <Header {...JSON.parse(node.getAttribute('data'))} />
      </BrowserRouter>
    )
  })
  Array.from(document.querySelectorAll('.project-follow-button')).forEach(function (
    node
  ) {
    const data = JSON.parse(node.getAttribute('data'))
    const root = createRoot(node)
    root.render(<ProjectFollowButton {...data} />)
  })
  Array.from(document.querySelectorAll('.save-for-later-button')).forEach(function (
    node
  ) {
    const data = JSON.parse(node.getAttribute('data'))
    const root = createRoot(node)
    root.render(<SaveForLaterButton {...data} />)
  })
  Array.from(document.querySelectorAll('.reaction-button')).forEach(function (
    node
  ) {
    const data = JSON.parse(node.getAttribute('data'))
    const root = createRoot(node)
    root.render(<ReactionButton {...data} />)
  })
  Array.from(document.querySelectorAll('.public-project-submissions-index')).forEach(function (
    node
  ) {
    const data = JSON.parse(node.getAttribute('data'))
    const root = createRoot(node)
    root.render(
      <BrowserRouter>
        <PublicProjectSubmissionsIndex {...data} />
      </BrowserRouter>
    )
  })
  attachReact('public-project-comments-index', function (node) {
    const root = createRoot(node)
    root.render(
      <BrowserRouter>
        <PublicProjectCommentsIndex {...JSON.parse(node.getAttribute('data'))} />
      </BrowserRouter>
    )
  })
  attachReact('submission-edit', function (node) {
    const root = createRoot(node)
    root.render( <SubmissionUploadEdit {...JSON.parse(node.getAttribute('data'))} />)
  })
  attachReact('flag-project', function (node) {
    const root = createRoot(node)
    root.render( <FlagProjectModal {...JSON.parse(node.getAttribute('data'))} />)
  })
  attachReact('react-new-feature-alert', function (node) {
    const root = createRoot(node)
    root.render( <NewFeatureAlert {...JSON.parse(node.getAttribute('data'))} />)
  })

  attachReact('onboarding-usermeta', function (node) {
    const root = createRoot(node)
    root.render(
      <OnboardingUserMeta {...JSON.parse(node.getAttribute('data'))} />
    )
  })
  attachReact('public-demos', function (node) {
    const root = createRoot(node)
    root.render(
      <LoggedOutDemosIndex {...JSON.parse(node.getAttribute('data'))} />
    )
  })
  attachReact('logged-out-submission', function (node) {
    const root = createRoot(node)
    root.render(
      <LoggedOutSubmissionShow {...JSON.parse(node.getAttribute('data'))} />
    )
  })
  attachReact('logged-out-talent-search', function (node) {
    const root = createRoot(node)
    root.render(
      <LoggedOutTalentSearchIndex {...JSON.parse(node.getAttribute('data'))} />
    )
  })
  attachReact('logged-out-open-projects', function (node) {
    const root = createRoot(node)
    root.render(
      <LoggedOutOpenProjectsSearchIndex {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('project-ads', function (node) {
    const root = createRoot(node)
    root.render( <ProjectAds {...JSON.parse(node.getAttribute('data'))} />)
  })
})
