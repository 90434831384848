import React, { useEffect } from 'react'

import FillOutProfile from '@/pages/Dashboard/FillOutProfile'
import AccountStats from '@/pages/Dashboard/AccountStats'
import WhoViewedMyProfile from '@/pages/Dashboard/WhoViewedMyProfile'
import FavoritedProjects from '@/pages/Dashboard/FavoritedProjects'
import RecentRoles from '@/pages/Dashboard/RecentRoles'
import UnreviewedProjectSubmissions from '@/pages/Dashboard/UnreviewedProjectSubmissions'
import ProfileStats from '@/pages/Dashboard/ProfileStats'
import RecentMessages from '@/pages/Dashboard/RecentMessages'
import Feed from '@/pages/Dashboard/Feed'
import Streak from '@/pages/Streak'

// Latest Activity Stream
  // - Top 5 save for later
  // - Top 5 saved searches
  // - Top 5 projects you might like
  // - Forum posts
  // - other follow activity (project updates, new projects, new comments, new casts, new auditions)
// Last 5 messages
// Last 5 cast roles

const DashboardIndex = () => {

  useEffect(() => {
    window.ezstandalone = window.ezstandalone || {}
    window.ezstandalone.cmd = window.ezstandalone.cmd || []
    window.ezstandalone.cmd.push(function() {
        window.ezstandalone?.define(106, 107)
        window.ezstandalone?.enable()
        window.ezstandalone?.display()
        window.ezstandalone?.refresh()
    })
  }, [])

  return <div className='mt-3'>
    <FillOutProfile />
    <div className='mt-4' />
    <AccountStats />
    <div className='mt-4' />
    <Streak />
    <div className='mt-4' />

    <div className='grid grid-cols-3 gap-x-3'>
      <div className='col-span-3 sm:col-span-2 sm:pr-3 bg-gray-100 rounded-lg dark:bg-gray-700'>
        <Feed />
      </div>
      <div className='col-span-3 sm:col-span-1 bg-gray-100 rounded-lg dark:bg-gray-700'>
        <WhoViewedMyProfile />
        <div className='mt-1' id='ezoic-pub-ad-placeholder-106' />
        <div className='mt-2' />
        <ProfileStats />
        <div className='mt-3' />
        <FavoritedProjects />
        <div className='mt-3' />
        <RecentRoles />
        <div className='mt-1' id='ezoic-pub-ad-placeholder-107' />
        <div className='mt-2' />
        <RecentMessages />
        <div className='mt-3' />
        <UnreviewedProjectSubmissions />
      </div>
    </div>
  </div>
}
export default DashboardIndex
