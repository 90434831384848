import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { StarIcon, LinkIcon, TicketIcon, FilmIcon } from '@heroicons/react/solid'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'

import CreditTabs from '@/pages/Credits/Tabs'
import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'
import NoCredits from '@/pages/Credits/NoCredits'
import UserHoverCard from '@/shared/UserHoverCard'
import UserCreditForm from '@/pages/Credits/UserCreditForm'
import { useGlobalState } from '@/state'

import Card from '@/shared/Card'

const CreditShow = () => {
  const { slug } = useParams()
  const { openModal, closeModal, isOpen } = useModal()
  const [credit, setCredit] = useGlobalState('credit')
  const [, setToast] = useGlobalState('toast')
  const [userCredits, setUserCredits] = useState([])
  const [following, setFollowing] = useState(false)
  const { getRequest, putpostRequest } = useQuery()

  useEffect(() => {
    getRequest(`/api/v3/credits/${slug}`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setCredit(jsonData.credit)
      setFollowing(jsonData.isFollowing)
    })
  }, [slug])

  useEffect(() => {
    if (credit.id) {
      getRequest(`/api/v3/credits/${credit.id}/user_credits`, {}, (err, jsonData) => {
        if (err) { /* handled in hook */ return }
        setUserCredits(jsonData.userCredits)
      })
    }
  }, [credit])

  const follow = () => {
    putpostRequest(`/api/v3/follows/${credit.id}/follow_credit`, 'POST', {}, (err, jsonData) => {
      if (err) { return }
      setFollowing(!following)
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">{!following === true ? 'Following!' : 'Stopped following' }</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">{!following === true ? 'You will see activity from this project in your dashboard.' : 'You will no longer see activity about this project'}</p>
      </div>)
    })
  }

  if (!credit.id) {
    return <Loading />
  }

  let pillColor = 'bg-cccorange'
  if (credit.stage === 'announced') {
    pillColor = 'bg-green-500'
  } else if (credit.stage === 'PreProduction') {
    pillColor = 'bg-yellow-500'
  } else if (credit.stage === 'Production') {
    pillColor = 'bg-cccblue'
  } else if (credit.stage === 'PostProduction') {
    pillColor = 'bg-cccorange'
  }

  return (
    <div className="flex-1 relative z-0 flex">
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
        <CreditTabs credit={credit} />
        <div className="grid grid-cols-3 sm:space-x-5 space-y-4 sm:space-y-0">
          <section className="col-span-3 sm:col-span-2">
            {/* Credit header */}
            <div className='max-w-3xl min-w-2xl flex-grow'>
              <div>
                <img className="h-40 w-full object-cover lg:h-48 shadow-md rounded rounded-lg" src={credit.coverImg} alt="" />
              </div>
              <div className="max-w-5xl mx-auto">
                <div className="-mt-16 ml-6 sm:-mt-32 sm:flex sm:items-end sm:space-x-5">
                  <div className="flex">
                    <img className="h-24 w-24 rounded-sm ring-4 ring-white dark:ring-gray-700 sm:h-52 sm:w-52" src={credit.imageUrl} alt={credit.name} />
                  </div>
                  <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                    <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                      <button onClick={openModal} type="button"
                        className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-gray-800 dark:text-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue"
                      >
                        <TicketIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span>Request to be Credited</span>
                      </button>
                      <button onClick={follow} type="button"
                        className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-gray-800 dark:text-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue"
                      >
                        <StarIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span>{following ? 'Unfollow' : 'Follow' }</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='mt-4' />
                <Card light title='About'>
                  <div className='flex flex-col'>
                    <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5 flex items-center">
                      {credit.year}
                      { credit.stage && <span className='flex justify-start ml-2'>
                        <span className={`flex-shrink-0 inline-block px-2 py-0.5 text-white ${pillColor} text-xs font-medium rounded-full flex cursor-pointer capitalize`}>
                          {credit.stage}
                        </span>
                      </span> }
                    </span>
                    <a href={credit.url} className='flex items-center' target='_blank' rel='noreferrer'>
                      <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span>{credit.url}</span>
                    </a>
                    <div className="prose max-w-none mt-2 p-2 rounded-sm dark:bg-gray-700">
                      <p className="text-base text-gray-500 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: credit.description }}></p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </section>
          <aside className='col-span-3 sm:col-span-1'>
            <div className='flex justify-between space-x-2 mb-2'>
              <Link to={`/credits/${credit.nextCreditId}`} className="w-full flex items-center justify-center p-2 border text-sm font-medium rounded-md shadow-sm bg-white dark:bg-gray-800">Next Credit</Link>
              <Link to={`/credits/${credit.randomCreditId}`} className="w-full flex items-center justify-center p-2 border text-sm font-medium rounded-md shadow-sm bg-white dark:bg-gray-800">Random Credit</Link>
            </div>
            <Card title="Credited Members" >
              { userCredits.length === 0 && <NoCredits /> }
              <ul className="space-y-3">
                { userCredits.map((userCredit, idx) => (
                  <li key={`pubuscre${userCredit.id}`} className='bg-white dark:bg-gray-700 px-2 py-2 sm:px-3 rounded-lg shadow-sm'>
                    <div className="flex items-start space-x-1 sm:space-x-3">
                      <div className="relative m-1">
                        <img className="h-8 w-8 sm:h-10 sm:w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={userCredit.userImageUrl} alt={userCredit.userDisplayName} />

                        <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
                          <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="flex-1 py-0 w-full truncate">
                        <div className='flex flex-col truncate'>
                          <div className='flex items-start justify-between space-x-1'>
                            <div className='flex flex-col truncate'>
                              <div className='flex items-center justify-start space-x-3'>
                                <span className="uppercase text-xs dark:text-gray-300 text-gray-500"> {userCredit.year} </span>
                              </div>
                              { userCredit.parentName && <span className='text-sm dark:text-gray-300 text-gray-500'>Part of series: <Link to={`/credits/${userCredit.parentSlug}`} className="text-cccblue">{userCredit.parentName}</Link></span> }
                              <span><UserHoverCard username={userCredit.username} displayName={userCredit.userDisplayName} /></span>
                              <Link to={`/credits/${userCredit.slug}`} className="font-medium text-cccblue truncate">{userCredit.name}</Link>
                              <span className='dark:text-gray-300 text-gray-500 flex space-x-6'> <i>{userCredit.creditType}</i> <b> {userCredit.role }</b></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </Card>
          </aside>
        </div>
      </main>
      <Modal full isOpen={isOpen} closeModal={closeModal} >
        <UserCreditForm
          creditId={credit.id}
          initialUserCredit={{}}
          setCreatingNewUserCredit={closeModal}
          myselfOnly
        />
      </Modal>
    </div>
  )
}

export default CreditShow
